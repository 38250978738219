<template>
  <v-row>
    <v-col cols="12" class="pt-0 pb-5">
      <!-- current digital certificate -->
      <v-row class="mt-5 px-1" v-if="loaderCertificate">
        <v-col cols="6" class="pa-0" v-for="item in 2" :key="item">
          <v-skeleton-loader class="mt-n3 rounded-md pa-2" transition="fade-transition" type="image" />
        </v-col>
      </v-row>
       <template v-else>
        <v-card v-if="!$helpers.hasPermission(['view_genericcertificate'])" class="my-5" flat>
          <PermissionDenied :isDetail="true" />
        </v-card>
        <template v-else>
          <v-row  no-gutters>
            <v-col cols="12" class="py-0">
              <v-card ref="certificate" flat>
                <v-toolbar color="transparent" flat height="46">
                  <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-bold">
                    <v-row no-gutters align="center" justify="space-between">
                      <span class="subtitle-2 grey-700--text font-weight-bold d-flex align-center"><v-icon size="16" left color="grey-700">mdi-stamper</v-icon>Certificado digital</span>
                    </v-row>
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items>
                    <v-row align="center" justify="center" class="mr-5">
                      <v-btn v-if="$helpers.hasPermission(['add_genericcertificate'])" @click="modalCertificate=true" outlined :ripple="false"><v-icon left >mdi-upload</v-icon>Subir certificado</v-btn>
                    </v-row>
                  </v-toolbar-items>
                </v-toolbar>
                <v-divider />
                <v-card-text class="px-5 py-4">
                  <v-col class="pb-0 pt-2 text-center" v-if="!certificatesList?.length">
                    <v-empty-state type="certificate" customClass="mt-8" :isFree="true" title="Aún no tienes certificados" :height="169" />
                  </v-col>
                  <v-list class="v-list-form-2-actions transparent pa-0" v-else>
                    <v-row no-gutters>
                      <v-col cols="7" class="pa-0">
                        <v-list-item class="pa-0">
                        <v-list-item-title class="font-weight-semibold d-flex align-center">
                          <v-icon color="grey-700" size="14" left>mdi-account</v-icon>
                          Nombre
                        </v-list-item-title>
                        <v-list-item-subtitle class="body-1 grey-500--text">{{certificatesList[0]?.name}}</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item class="pa-0">
                          <v-list-item-title class="font-weight-semibold d-flex align-center">
                            <v-icon color="grey-700" size="14" left>mdi-calendar-blank</v-icon>
                            Validez
                            <v-tooltip color="grey-background" v-if="certificatesList[0]?.date_end && dateIsBefore(certificatesList[0]?.date_end, today)" right max-width="280px" transition="scale-transition">
                              <template v-slot:activator="{on}">
                                <v-icon v-on="on" small color="red">mdi-information</v-icon>
                              </template>
                              <span class="d-block px-3 py-2">El certificado digital está vencido. Para resolver el problema compra un nuevo certificado y súbelo a Axteroid.</span>
                            </v-tooltip>
                          </v-list-item-title>
                          <v-list-item-subtitle class="body-1 grey-500--text">desde el {{certificatesList[0]?.date_start | date}} hasta el {{certificatesList[0]?.date_end | date}}</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item class="pa-0">
                          <v-list-item-title class="font-weight-semibold d-flex align-center">
                            <v-icon color="grey-700" size="14" left>mdi-domain</v-icon>
                            Emisor
                          </v-list-item-title>
                          <v-list-item-subtitle class="body-1 grey-500--text">{{certificatesList[0]?.issuer}}</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item class="pa-0">
                          <v-list-item-title class="font-weight-semibold d-flex align-center">
                            <v-icon color="grey-700" size="14" left>mdi-pound-box-outline</v-icon>
                            Serial
                          </v-list-item-title>
                          <v-list-item-subtitle class="body-1 grey-500--text">{{certificatesList[0]?.serial}}</v-list-item-subtitle>
                        </v-list-item>
                      </v-col>
                      <v-col cols="5" class="pa-0">
                        <v-list-item class="pa-0">
                          <v-list-item-title class="font-weight-semibold d-flex align-center">
                            <v-icon color="grey-700" size="14" left>mdi-card-account-details</v-icon>
                            {{ $t('generals.RUT') }}
                          </v-list-item-title>
                          <v-list-item-subtitle class="body-1 grey-500--text">{{certificatesList[0]?.tax_id}}</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item class="pa-0">
                          <v-list-item-title class="font-weight-semibold d-flex align-center">
                            <v-icon color="grey-700" size="14" left>mdi-id-card</v-icon> <!--mdi-card-account-details-->
                            ID
                          </v-list-item-title>
                          <v-list-item-subtitle class="body-1 grey-500--text">{{certificatesList[0]?.digest}}</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item class="pa-0">
                          <v-list-item-title class="font-weight-semibold d-flex align-center">
                            <v-icon color="grey-700" size="14" left>mdi-email</v-icon>
                            Email
                          </v-list-item-title>
                          <v-list-item-subtitle class="body-1 grey-500--text">{{certificatesList[0]?.email}}</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item class="pa-0">
                          <v-list-item-title class="font-weight-semibold d-flex align-center">
                            <v-icon color="grey-700" size="14" left>mdi-calendar-blank</v-icon>
                            Fecha de subida
                          </v-list-item-title>
                          <v-list-item-subtitle class="body-1 grey-500--text">{{certificatesList[0]?.created | dateTimeSecond}}</v-list-item-subtitle>
                        </v-list-item>
                      </v-col>
                    </v-row>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </template>
        <!-- end current digital certificate -->

        <!-- other digital certificate -->
        <v-expansion-panels class="mt-5" v-model="panelOtherCertificates" v-if="otherCertificatesList?.length">
          <v-expansion-panel>
            <v-expansion-panel-header class="px-4 py-3">
              <v-row no-gutters align="center" justify="space-between">
                <span class="subtitle-2 grey-700--text font-weight-bold d-flex align-center"><v-icon size="16" left color="grey-700">mdi-stamper</v-icon>Certificado digital anteriores</span>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="px-0">
              <v-divider />
              <v-col cols="12" class="py-2" v-for="(cert, index) in otherCertificatesList" :key="index">
                <v-list class="v-list-form-2-actions transparent pa-0">
                  <v-row no-gutters>
                    <v-col cols="7" class="pa-0">
                      <v-list-item class="pa-0">
                      <v-list-item-title class="font-weight-semibold d-flex align-center">
                        <v-icon color="grey-700" size="14" left>mdi-account</v-icon>
                        Nombre
                      </v-list-item-title>
                      <v-list-item-subtitle class="body-1 grey-500--text">{{cert.name}}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item class="pa-0">
                        <v-list-item-title class="font-weight-semibold d-flex align-center">
                          <v-icon color="grey-700" size="14" left>mdi-calendar-blank</v-icon>
                          Validez
                          <v-tooltip color="grey-background" v-if="cert?.date_end && dateIsBefore(cert?.date_end, today)" right max-width="280px" transition="scale-transition">
                            <template v-slot:activator="{on}">
                              <v-icon v-on="on" small color="red">mdi-information</v-icon>
                            </template>
                            <span class="d-block px-3 py-2">El certificado digital está vencido. Para resolver el problema compra un nuevo certificado y súbelo a Axteroid.</span>
                          </v-tooltip>
                        </v-list-item-title>
                        <v-list-item-subtitle class="body-1 grey-500--text">desde el {{cert.date_start | date}} hasta el {{cert.date_end | date}}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item class="pa-0">
                        <v-list-item-title class="font-weight-semibold d-flex align-center">
                          <v-icon color="grey-700" size="14" left>mdi-domain</v-icon>
                          Emisor
                        </v-list-item-title>
                        <v-list-item-subtitle class="body-1 grey-500--text">{{cert.issuer}}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item class="pa-0">
                        <v-list-item-title class="font-weight-semibold d-flex align-center">
                          <v-icon color="grey-700" size="14" left>mdi-pound-box-outline</v-icon>
                          Serial
                        </v-list-item-title>
                        <v-list-item-subtitle class="body-1 grey-500--text">{{cert.serial}}</v-list-item-subtitle>
                      </v-list-item>
                    </v-col>
                    <v-col cols="5" class="pa-0">
                      <v-list-item class="pa-0">
                        <v-list-item-title class="font-weight-semibold d-flex align-center">
                          <v-icon color="grey-700" size="14" left>mdi-card-account-details</v-icon>
                          {{ $t('generals.RUT') }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="body-1 grey-500--text">{{cert.tax_id}}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item class="pa-0">
                        <v-list-item-title class="font-weight-semibold d-flex align-center">
                          <v-icon color="grey-700" size="14" left>mdi-id-card</v-icon> <!--mdi-card-account-details-->
                          ID
                        </v-list-item-title>
                        <v-list-item-subtitle class="body-1 grey-500--text">{{cert.digest}}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item class="pa-0">
                        <v-list-item-title class="font-weight-semibold d-flex align-center">
                          <v-icon color="grey-700" size="14" left>mdi-email</v-icon>
                          Email
                        </v-list-item-title>
                        <v-list-item-subtitle class="body-1 grey-500--text">{{cert.email}}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item class="pa-0">
                        <v-list-item-title class="font-weight-semibold d-flex align-center">
                          <v-icon color="grey-700" size="14" left>mdi-calendar-blank</v-icon>
                          Fecha de subida
                        </v-list-item-title>
                        <v-list-item-subtitle class="body-1 grey-500--text">{{cert.created | dateTimeSecond}}</v-list-item-subtitle>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-list>
                <v-divider class="mt-3" v-if="otherCertificatesList?.length - 1 !== 0" />
              </v-col>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- end other digital certificate -->
      </template>

      <!-- folios -->
      <template v-if="$store?.state?.auth?.account?.country === 'CL'">
        <v-col cols="12" class="pa-0 mt-5" v-if="numberingLoading">
          <v-skeleton-loader class="rounded-md" transition="fade-transition" type="image" />
        </v-col>
        <v-card class="mt-5" flat v-else>
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-bold">
              <span class="subtitle-2 grey-700--text font-weight-bold d-flex align-center"><v-icon size="16" left color="grey-700">mdi-file-document-multiple</v-icon>{{ $t('generals.Numeración') }}</span>
            </v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-4">
                <v-btn @click="cafDialog=true" outlined :ripple="false"><v-icon left >mdi-upload</v-icon>Agregar</v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
          <v-divider />
          <v-card-text class="pa-0">
            <template v-if="numberingList?.length">
              <v-data-table class="table-not-rounded" :headers="headers.filter(({countries}) => countries.includes($store?.state?.auth?.account?.country))" :items="numberingList" hide-default-header disable-sort hide-default-footer>
                <!-- header -->
                <template v-slot:header="{ props: { headers } }">
                  <VTableHeaders :headers="headers" :sortable="false" />
                  <v-divider style="min-width: 100%; position: absolute;  margin-top: -6px; margin-left: -5px;" />
                </template>
                <!-- end header -->
                <template v-slot:[`item.document_type`]="{item}">
                  <span>{{item.document_type | nameDocumentsType}}</span>
                </template>
                <template v-slot:[`item.restantes`]="{item}">
                  <span>{{(item.end - item.current) + 1}}</span>
                </template>
                <template v-slot:[`item.created`]="{item}">
                  <span>{{item.created | date}}</span>
                </template>
              </v-data-table>
            </template>
            <v-empty-state class="mt-n2 pb-2" v-else type="documents" id="documentos" customClass="mt-n5" :isFree="true" :title="`Aún no existe ${$t('generals.numeración')}`" :height="199" />
          </v-card-text>
        </v-card>
      </template>
      <!-- end folio -->
    </v-col>
    <!-- dialog new numbering -->
    <VUpload :cafDialog.sync="cafDialog" @getList="getNumbering" />
    <!-- end dialog new numbering -->

    <!-- dialog certificate -->
    <v-dialog v-model="modalCertificate" width="700" persistent no-click-animation scrollable overlay-color="grey-500">
      <v-card flat>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-semibold">Nuevo certificado digital</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="clear" icon small retain-focus-on-click><v-icon color="grey-500" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-5 py-4">
          <v-list class="v-list-form transparent pa-0">
            <v-list-item class="pa-0">
              <v-list-item-title>
                Archivo del certificado
                <v-tooltip color="grey-background" right transition="scale-transition" max-width="200px">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="grey-700">mdi-information</v-icon>
                  </template>
                  <span class="d-block px-3 py-2">Asegúrate que el certificado digital tenga formato/extensión .p12</span>
                </v-tooltip>
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-file-input v-model="newCertificate.file" ref="newCertificate.file" :error="$v.newCertificate.file.$error" type="file" accept="application/x-pkcs12" prepend="mdi-paper-clip" background-color="transparent" hide-details placeholder="No se eligió ningún archivo" solo flat chips dense />
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="pa-0">
              <v-list-item-title>
                Contraseña
                <v-tooltip color="grey-background" right transition="scale-transition" max-width="200px">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="grey-700">mdi-information</v-icon>
                  </template>
                  <span class="d-block px-3 py-2">La contraseña que ingresaste al comprar el certificado digital</span>
                </v-tooltip>
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-col cols="8">
                  <v-text-field v-model="$v.newCertificate.password.$model" :error="$v.newCertificate.password.$error" type="password" outlined required single-line dense hide-details />
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer />
          <v-btn @click="clear" outlined color="grey-700">Cancelar</v-btn>
          <v-btn @click="createCertificate" :loading="$store.state.irs.createLoader" color="blue-500">Subir certificado</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog certificate -->
  </v-row>
</template>
<script>
import moment from 'moment'
import { mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
// import moment from 'moment'
import VTableHeaders from '@/components/VTable/VTableHeaders'
import VUpload from '@/components/commons/VUpload'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import ListViewMixin from '@/mixins/ListViewMixin'
import VEmptyState from '@/components/commons/VEmptyState'
import documentTypesList from '@/collections/documentTypes'
import PermissionDenied from '@/modules/http/views/PermissionDenied'

const documentTypes = documentTypesList.filter(d => d.country === 'CL')

export default {
  components: {
    VEmptyState, PermissionDenied, VTableHeaders, VUpload
  },
  mixins: [
    GenericViewMixin, ListViewMixin
  ],
  data: () => ({
    numberingLoading: true,
    cafDialog: false,
    headers: [
      { text: 'Tipo de documento', value: 'document_type', class: 'font-weight-bold', sortable: false, countries: ['CL', 'PE'] },
      { text: 'Serie', value: 'series', align: 'end', sortable: false, countries: ['PE'] },
      { text: 'Desde', value: 'start', align: 'end', sortable: false, countries: ['CL', 'PE'] },
      { text: 'Hasta', value: 'end', align: 'end', sortable: false, countries: ['CL', 'PE'] },
      { text: 'Actual', value: 'current', align: 'end', sortable: false, countries: ['CL', 'PE'] },
      { text: 'Restantes', value: 'restantes', align: 'end', sortable: false, countries: ['CL', 'PE'] },
      { text: 'Asignado el', value: 'created', align: 'end', sortable: false, countries: ['CL', 'PE'] }
    ],
    panelOtherCertificates: 1,
    documentTypes: documentTypes,
    modalCertificate: false,
    loaderCertificate: false,
    newCertificate: {
      file: null,
      password: ''
    },
    dialogCreateFolio: false
  }),
  computed: {
    ...mapState({
      instance: state => state.irs.connectionsList[0] || {},
      certificatesList: state => state.irs.certsList
    }),
    otherCertificatesList () {
      let list = [...this.certificatesList]
      return list.slice(1)
    }
  },
  watch: {
    'folios.type': {
      handler (val) {
        if (['CL34', 'CL61', 'CL56', 'CL46'].includes(val) && this.$store?.state?.auth?.account?.country === 'CL') {
          const expirationDate = moment(this.folios.date, "YYYY-MM-DD").add(6, "months").format("YYYY-MM-DD")
          if (moment().isAfter(expirationDate)) {
            this.isValidateDate = true
            this.$dialog.message.error('El CAF está vencido. Por favor, solicita un nuevo.')
          }
        }
      },
        deep: true
    },
    $route () {
      this.getNumbering()
    }
  },
  created () {
    if (this.$helpers.hasPermission(['view_genericcertificate'])) {
      this.getList()
    }
    this.getNumbering()
  },
  mounted () {
    setTimeout(() => {
      if (this.$route.query.ref && this.$refs[this.$route.query.ref]) {
        this.$vuetify.goTo(this.$refs[this.$route.query.ref].$el, { offset: 20 })
      }
    }, 1500)
  },
  methods: {
    getList () {
      if (this.$helpers.hasPermission(['view_genericcertificate'])) {
        this.loaderCertificate = true
        this.$store.dispatch('irs/LIST', {
          resource: `certs`
        })
        .finally(() => {
          this.loaderCertificate = false
        })
      }
    },
    clear () {
      this.modalCertificate = false
      this.newCertificate = {
        file: null,
        password: ''
      }
      this.$v.newCertificate.$reset()
    },
    createCertificate () {
      this.$v.newCertificate.$touch()
      if (this.$v.newCertificate.$invalid) {
        return false
      }

      const payload = new FormData()
      payload.append('certificate', this.newCertificate.file, this.newCertificate.file.name)
      payload.append('password', this.newCertificate.password)

      this.$store.dispatch('irs/CREATE', {
        resource: `certs`,
        payload: payload
      })
      .then(() => {
        this.$dialog.message.info('El certificado digital ha sido creado con éxito')
        this.getList()
      })
      .catch((error) => {
        this.$dialog.message.error(error.response.data.message)
      })
      .finally(() => {
       this.clear()
      })
    },
    getNumbering () {
      this.numberingList = []
      this.numberingLoading = true
      this.$store.dispatch('irs/LIST', {
        resource: `numbering`
      })
      .then((resp) => {
        this.numberingList = resp.data
      })
      .finally(() => {
        this.numberingLoading = false
      })
    }
  },
  validations: {
    newCertificate: {
      file: {
        required
      },
      password: {
        required
      }
    }
  }
}
</script>